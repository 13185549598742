//Polyfill
import "core-js";

import jQuery from "jquery";
const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)

//Can't resolve 'regenerator-runtime/runtime'が表示されたとき(async awaitを使う場合など)
//import  regeneratorRuntime  from  "regenerator-runtime" ;

//bootstrapのmodalを読み込み
// import "/node_modules/bootstrap/js/dist/modal";

$(function () {
  //トグルで展開
  $(".section-area-box--title").on("click", function () {
    $(this).next().stop().slideToggle();
    $(this).toggleClass("is-active");
    $(this).next().toggleClass("is-open");
  });

  //スマホメニューの展開
  var state = false;
  var scrollpos;
  $(".menu-trigger").on("click", function () {
    if (state == false) {
      scrollpos = $(window).scrollTop();
      $("body").addClass("fixed").css({ top: -scrollpos });
      $(".menuBox").stop().slideDown(300);
      $(".menu-trigger").addClass("active");
      state = true;
    } else {
      $("body").removeClass("fixed").css({ top: 0 });
      window.scrollTo(0, scrollpos);
      $(".menuBox").stop().slideUp(300);
      $(".menu-trigger").removeClass("active");
      state = false;
    }
    return false;
  });
  $(".nav-close").on("click", function () {
    $("body").removeClass("fixed").css({ top: 0 });
    window.scrollTo(0, scrollpos);
    $(".menuBox").stop().slideUp(300);
    $(".menu-trigger").removeClass("active");
    state = false;
  });
  //施工会社一覧の展開
  $(".organization-open").on("click", function () {
    $(this).next().stop().slideToggle();
    $(this).toggleClass("is-active");
    $(this).next().toggleClass("is-open");
  });
});
